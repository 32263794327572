// Colors
$local-topbar: #22D0FF;
$local-topbar-text: #ffffff;
$local-bkg: #272727;
$local-bkg-image: none;
$local-text: #ffffff;

// Font
$local-text-font: 'Open Sans', sans-serif;
$local-headline-font: 'Oswald', sans-serif;

// Dimensions
$local-topbar-height: 70px;
$local-logo-width: 240px;
$local-logo-margin-top: 5%;
$local-logo-margin-bottom: 3%;
